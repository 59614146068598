MatchHeight.init()

//** Modal Control START */
//На открытие обратного звонка вешать класс .open-callback
//На открытие номеров вешать класс .open-phone
window.addEventListener("load", function () {
  var modalCallback = document.getElementById("callback-modal")
  var openCallback = document.getElementsByClassName("open-callback")
  var closeCallback = document.getElementsByClassName("close-callback")[0]
  const debounceSetY = debounce(
    () => document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`), 300
  )
  window.addEventListener('scroll', debounceSetY);
  //Получаем текущую высоту скрола и пропсиываем его в боди
  function setBodyPosition() {
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
    const body = document.body;
    body.style.position = 'fixed';
    body.style.top = `-${scrollY}`;
    body.style.maxWidth = `100vw`;
  } 
  function clearBodyPosition() {
    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }
  //если на странице есть кнопки октрытия модальных окон, вешаем на них событие
  if (openCallback && closeCallback) {
    for(let i = 0; i < openCallback.length; i++){
        openCallback[i].onclick = function(){
            modalCallback.style.display = "block"
            this.dataset.title 
              ? modalCallback.querySelector("#title-product").value = this.dataset.title
              : modalCallback.querySelector("#title-product").value = ""
              
            setBodyPosition()
        }
    }
    closeCallback.onclick = function () {
        modalCallback.style.display = "none"
        modalCallback.querySelector('form').reset();
        modalCallback.querySelector('form').style.display = "block"
        document.querySelector(".cbf-errors-container-submit").style.display = "none"
        clearBodyPosition()
    }
  }

  //Модал с номером телефона
  let modalPhone = document.getElementById('phone-modal')
  let openPhone = document.getElementsByClassName("open-phone")
  let closePhone = document.getElementById("close-phone")

  if (openPhone && closePhone) {
    for(let i = 0; i < openPhone.length; i++){
        openPhone[i].onclick = function(){
            modalPhone.style.display = "block"
            setBodyPosition()
        }
    }
    closePhone.onclick = function () {
      modalPhone.style.display = "none"
      clearBodyPosition()
    }
  }

  let closeAndOpen = document.getElementById('close-and-openModal')
  closeAndOpen && closeAndOpen.addEventListener('click', function(event){
    event.preventDefault()
    modalPhone.style.display = "none"
    modalCallback.style.display = "block"
  })


  //Модал с видео плеером
  let modalVideo = document.getElementById('video-modal')
  let openVideo = document.getElementsByClassName('open-video')
  let closeVideo = document.getElementById('close-video')

  
  if (openVideo && closeVideo) {
    let video = document.getElementById('video-player')
    for(let i = 0; i < openVideo.length; i++){
        openVideo[i].onclick = function(){
            modalVideo.querySelector('source').src = this.dataset.video + '?cache=' + Date.now();
            if (this.dataset.about) {
              console.log(true)
              modalVideo.querySelector('.modal-content').style.maxWidth = "70%"
              modalVideo.querySelector('.modal-content').style.width = "100%"
              
            } 
            video.load()
            modalVideo.style.display = "block"
            setBodyPosition()
            video.play()
        }
    }
    closeVideo.onclick = function () {
      modalVideo.style.display = "none"
      video.pause();
      clearBodyPosition()
    }
  }

  //Закрытие модалов по аутсайд клику
  window.onclick = function (event) {
    if (event.target == modalVideo) {
      modalVideo.style.display = "none"
      clearBodyPosition()
    }
    if (event.target == modalPhone) {
      modalPhone.style.display = "none"
      clearBodyPosition()
    }
    if (event.target == modalCallback) {
      modalCallback.style.display = "none"
      modalCallback.style.display = "none"
      modalCallback.querySelector('form').reset();
      modalCallback.querySelector('form').style.display = "block"
      document.querySelector(".cbf-errors-container-submit").style.display = "none"
      clearBodyPosition()
    }
  }
//** Modal Control END */


//** скрыть кнопку подробнее в категориях если она не нужна */
  //** Обрезка текста в описании категорий */
  const spoilerContainer = document.getElementsByClassName('spoiler__text');
  const textElement = document.getElementById('text-container');
  const moreButton = document.getElementById('more-btn');

  const text = spoilerContainer[0]?.textContent.trim()
  // Проверяем, нужно ли обрезать текст
  if (text){
    if (text.length > 300) {
      const truncatedText = text.slice(0, 300) + '...';
      textElement.textContent = truncatedText;
      moreButton.addEventListener('click', toggleText);
    } else {
      textElement.textContent = text;
      moreButton.style.display = 'none';
    }
  }


// Функция для показа полного текста
  function toggleText() {
    if (textElement.textContent === text) {
      const truncatedText = text.slice(0, 300) + '...';
      textElement.textContent = truncatedText;
      buttonElement.textContent = 'Подробнее';
    } else {
      textElement.textContent = text;
    }
  }
//Выводим имя файла в поле загрузки файла 
  let fileUpload = document.getElementById('file-upload')
  let labelUpload = document.querySelector('label[for=file-upload]')
  if (fileUpload){
    fileUpload.addEventListener('change', e => {
      let fileName = e.target.files[0].name
      if (fileName.length > 20) {
        let startName = fileName.slice(0,10)
        let endName = fileName.slice(fileName.length - 10)
        fileName = `${startName}...${endName}`
      } 
      labelUpload.innerHTML = fileName

    })
  }
  console.log('test',fileUpload)
  
/** window.addEventListener "load" END */  

})



console.log('ok')




/** Utils */
// Аргументами функции будут:
// - функция, которую надо «откладывать»;
// - интервал времени, спустя которое функцию следует вызывать.
function debounce(callee, timeoutMs) {
  // Как результат возвращаем другую функцию.
  // Это нужно, чтобы мы могли не менять другие части кода,
  // чуть позже мы увидим, как это помогает.
  return function perform(...args) {
    // В переменной previousCall мы будем хранить
    // временную метку предыдущего вызова...
    let previousCall = this.lastCall

    // ...а в переменной текущего вызова —
    // временную метку нынешнего момента.
    this.lastCall = Date.now()

    // Нам это будет нужно, чтобы потом сравнить,
    // когда была функция вызвана в этот раз и в предыдущий.
    // Если разница между вызовами меньше, чем указанный интервал,
    // то мы очищаем таймаут...
    if (previousCall && this.lastCall - previousCall <= timeoutMs) {
      clearTimeout(this.lastCallTimer)
    }

    // ...который отвечает за непосредственно вызов функции-аргумента.
    // Обратите внимание, что мы передаём все аргументы ...args,
    // который получаем в функции perform —
    // это тоже нужно, чтобы нам не приходилось менять другие части кода.
    this.lastCallTimer = setTimeout(() => callee(...args), timeoutMs)

    // Если таймаут был очищен, вызова не произойдёт
    // если он очищен не был, то callee вызовется.
    // Таким образом мы как бы «отодвигаем» вызов callee
    // до тех пор, пока «снаружи всё не подуспокоится».
  }
}

